import Home from "./pages/Home";
import Menu from "./components/Menu";
import Modal from "./components/Modal";

const nav = document.querySelector(".c-nav");
let oldScroll = window.scrollY;

const url = window.location.pathname;

document.addEventListener("scroll", (e) => {
  const newScroll = window.scrollY;

  if (oldScroll < newScroll && newScroll > 100) {
    nav.classList.add("scrolling");
  } else {
    nav.classList.remove("scrolling");
  }

  oldScroll = newScroll;
});

new Menu();
new Modal();
if (url === "/") {
  new Home();
}
