const isFunction = function (obj) {
  return typeof obj === "function" || false;
};

const isArray =
  Array.isArray ||
  function (obj) {
    return (
      !!obj &&
      typeof obj === "object" &&
      typeof obj.length === "number" &&
      objToString.call(obj) == "[object Array]"
    );
  };

function baseEach(collection, iterator) {
  const i = -1,
    len = collection.length;

  while (++i < len) {
    //here for you to return false to break iteration
    if (iterator(collection[i], i, collection) === false) {
      break;
    }
  }
  return collection;
}

let nativeEach = Array.prototype.forEach;
let arraySlice = Array.prototype.slice;

const each = (collection, iterator) => {
  if (!isArray(collection)) {
    // for array like objects
    if (
      !!collection &&
      typeof collection == "object" &&
      typeof collection.length === "number"
    ) {
      collection = arraySlice.call(collection);
    }
    // you could throw an error here
    return;
  }
  if (isFunction(nativeEach)) {
    nativeEach.call(collection, iterator);
  } else {
    baseEach(collection, iterator);
  }
};

export default each;
