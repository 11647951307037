import Component from "../classes/Component";
import AutoBind from "../utils/bind";
import { mapEach } from "../utils/dom";
import SubmitForm from "./SubmitForm";

export default class extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        modalButtons: "[data-modal]",
        modalWrappers: ".c-modal-wrapper",
        modalCloseButtons: ".c-modal .c-button--secondary",
      },
    });

    this.modalActiveClassName = "c-modal-wrapper--active";

    AutoBind(this);

    this.addEventListeners();
    this.initForms();
  }

  addEventListeners() {
    const { modalButtons, modalCloseButtons, modalWrappers } = this.elements;

    mapEach(modalButtons, (button) => {
      button.addEventListener("click", () => {
        const modalId = button.dataset.modal;
        const modal = document.getElementById(modalId);
        modal.classList.add(this.modalActiveClassName);
        modal.querySelector("input").focus();
      });
    });

    mapEach(modalCloseButtons, (button) => {
      button.addEventListener("click", () => {
        button
          .closest(".c-modal-wrapper")
          .classList.remove(this.modalActiveClassName);
      });
    });

    mapEach(modalWrappers, (wrapper) => {
      wrapper.style.transition = "300ms";
      wrapper.addEventListener("click", (e) => {
        if (e.target === wrapper) {
          wrapper.classList.remove(this.modalActiveClassName);
        }
      });
    });

    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape" || e.key === "Esc") {
        document
          .querySelector(`.${this.modalActiveClassName}`)
          .classList.remove(this.modalActiveClassName);
      }
    });
  }

  initForms() {
    const { modalWrappers } = this.elements;

    mapEach(modalWrappers, (wrapper) => {
      new SubmitForm(wrapper);
    });
  }
}
