import { TABLET } from "../utils/breakpoints";
import GSAP from "../utils/scrolltrigger";
const container = document.querySelector(".c-values");

const mm = GSAP.matchMedia();

export const valuesAnimation = () => {
  mm.add(`(min-width: ${TABLET}px)`, () => {
    GSAP.to(container, {
      x: () => {
        return -(container.scrollWidth - innerWidth);
      },
      ease: "none",
      overwrite: "auto",
      scrollTrigger: {
        trigger: container,
        invalidateOnRefresh: true,
        pin: true,
        scrub: 0.5,
        end: () => "+=" + (container.offsetWidth + innerWidth),
      },
    });
  });
};
