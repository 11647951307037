import Splitting from "splitting";
import Animation from "../classes/Animation";
import each from "../utils/each";

export default class extends Animation {
  constructor({ element }) {
    super({
      element,
    });

    const results = Splitting({ target: element, by: "lines" });

    this.lines = results[0].lines;

    each(this.lines, (line) => {
      each(line, (word) => {
        word.innerHTML = `<span>${word.innerHTML}</span>`;
      });
    });

    // this.onResize();

    if ("IntersectionObserver" in window) {
      this.animateOut();
    }
  }

  textIn() {
    each(this.lines, (line, lineIndex) => {
      each(line, (word, wordIndex) => {
        const newSpan = word.querySelector("span");
        newSpan.style.transition = `transform 1s ${
          (lineIndex + wordIndex) * 0.1
        }s ease`;
        newSpan.style[this.transformPrefix] = "translateY(0)";
      });
    });
  }

  textOut() {
    each(this.lines, (line) => {
      each(line, (word) => {
        const newSpan = word.querySelector("span");
        newSpan.style.willChange = "transform";
        newSpan.style.transformStyle = "preserve-3d";
        newSpan.style[this.transformPrefix] = "translateY(12vw)";
      });
    });
  }

  animateIn() {
    if (this.hasAnimatedIn) return;
    super.animateIn();

    this.element.classList.add("in-view");
    this.textIn();

    this.hasAnimatedIn = true;
  }

  animateOut() {
    if (this.hasAnimatedIn) return;
    super.animateOut();
    this.textOut();
  }

  // onResize() {
  //   const results = Splitting({ target: this.element, by: "lines" });
  //   this.lines = results[0].lines;
  // }
}
