import Component from "../classes/Component";
import Lenis from "@studio-freight/lenis";
import { animations } from "../animations";
import { mapEach } from "../utils/dom";
import Paragraph from "../components/Paragraph";
import Splitting from "splitting";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        texts: '[data-animation="text"]',
        splitChars: '[data-splitting="chars"]',
        plansLinks: '[href="#plans"]',
      },
    });

    this.initScroll();
    this.init();
    this.addEventListeners();
    this.update();
    animations();
  }

  initScroll() {
    this.lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
      direction: "vertical", // vertical, horizontal
      gestureDirection: "vertical", // vertical, horizontal, both
      smooth: true,
      mouseMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    });

    //get scroll value
    this.lenis.on("scroll", () => {
      ScrollTrigger.update();
      // console.log({ scroll, limit, velocity, direction, progress });
    });
  }

  update(time) {
    this.lenis.raf(time);
    requestAnimationFrame(this.update);
  }

  addEventListeners() {
    this.elements.plansLinks.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        this.lenis.scrollTo(document.querySelector("#plans"));
      });
    });
  }

  init() {
    mapEach(this.elements.texts, (element) => {
      return new Paragraph({ element });
    });

    mapEach(this.elements.splitChars, (element) => {
      Splitting({ target: element, by: "chars" });
    });
  }
}
