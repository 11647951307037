import Component from "../classes/Component";
import AutoBind from "../utils/bind";
import { mapEach } from "../utils/dom";

export default class extends Component {
  constructor(element) {
    super({
      element,
      elements: {
        formItems: "[name]",
        form: "form",
        formInner: ".c-form",
        buttons: "button",
        modalContent: ".c-modal__content",
      },
    });

    AutoBind(this);

    this.baseURL = "https://api.useonion.com/";
    this.name = this.elements.form.dataset.name;
    this.modalActiveClassName = "c-modal-wrapper--active";

    this.url = `${this.baseURL}${this.name}`;

    this.handleSubmit();
  }

  sendForm(formData) {
    if (this.name === "report-bug") {
      return;
    } else {
      return fetch(this.url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
    }
  }

  disableButtons() {
    const { buttons } = this.elements;
    mapEach(buttons, (button) => {
      button.disabled = true;
    });
  }

  enableButtons() {
    const { buttons } = this.elements;
    mapEach(buttons, (button) => {
      button.disabled = false;
    });
  }

  addErrorMessage() {
    const { formInner } = this.elements;
    const error = document.createElement("div");
    error.classList.add("c-modal__error");
    error.innerText = "Something went wrong. Let's give that one more try.";
    formInner.prepend(error);
  }

  removeErrorMessage() {
    const { formInner } = this.elements;
    const error = formInner.querySelector(".c-modal__error");

    if (error) {
      error.remove();
    }
  }

  handleSubmit() {
    this.element.addEventListener("submit", async (e) => {
      e.preventDefault();
      this.disableButtons();
      this.element.classList.add("loading");
      const { formItems, form } = this.elements;
      const formData = new FormData(form);
      const data = {};

      mapEach(formItems, (item) => {
        data[item.name] = formData.get(item.name);
      });

      try {
        this.removeErrorMessage();

        const res = await this.sendForm(data);

        if (res.status === 200) {
          this.element.classList.add("success");

          setTimeout(() => {
            form.reset();
            this.enableButtons();
            this.element.classList.remove("success");
            this.element.classList.remove(this.modalActiveClassName);
          }, 2000);
        } else {
          this.addErrorMessage();
          this.enableButtons();
        }
      } catch (error) {
        this.addErrorMessage();
        this.enableButtons();
      } finally {
        this.elements.modalContent.scrollTop = 0;
        this.element.classList.remove("loading");
      }
    });
  }
}
