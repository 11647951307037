import GSAP from "../utils/scrolltrigger";

const tl = GSAP.timeline({
  scrollTrigger: {
    trigger: ".c-hero",
  },
});

export const heroAnimation = () => {
  tl.fromTo(
    "[data-cursor-right-1]",
    {
      x: -30,
      y: -30,
      opacity: 0,
    },
    {
      duration: 1,
      ease: "power2.inOut",
      opacity: 1,
      x: 0,
      y: 0,
    }
  )
    .fromTo(
      "[data-cursor-right-2]",
      {
        duration: 1,
        y: -30,
        x: 30,
        opacity: 0,
        ease: "power2.inOut",
      },
      {
        duration: 1,
        ease: "power2.inOut",
        opacity: 1,
        x: 0,
        y: 0,
      },
      "-=90%"
    )
    .fromTo(
      "[data-cursor-right-3]",
      {
        duration: 1,
        y: 30,
        x: 30,
        opacity: 0,
        ease: "power2.inOut",
      },
      {
        duration: 1,
        ease: "power2.inOut",
        opacity: 1,
        x: 0,
        y: 0,
      },
      "-=90%"
    )
    .fromTo(
      "[data-cursor-left-1]",
      {
        duration: 1,
        y: -30,
        x: -30,
        opacity: 0,
        ease: "power2.inOut",
      },
      {
        duration: 1,
        ease: "power2.inOut",
        opacity: 1,
        x: 0,
        y: 0,
      },
      "-=100%"
    )
    .fromTo(
      "[data-cursor-left-2]",
      {
        duration: 1,
        y: 30,
        x: -30,
        opacity: 0,
        ease: "power2.inOut",
      },
      {
        duration: 1,
        ease: "power2.inOut",
        opacity: 1,
        x: 0,
        y: 0,
      },
      "-=100%"
    )
    // .fromTo(
    //   ".c-hero__beta",
    //   {
    //     duration: 1,
    //     scale: 1.2,
    //     opacity: 0,
    //   },
    //   {
    //     duration: 1,
    //     scale: 1,
    //     opacity: 1,
    //     ease: "expo.out",
    //   },
    //   "-=80%"
    // )
    .fromTo(
      ".c-hero__subtext",
      {
        duration: 1,
        y: 30,
        opacity: 0,
      },
      {
        duration: 1,
        y: 0,
        opacity: 1,
        ease: "expo.out",
      },
      "-=50%"
    )
    .fromTo(
      ".c-hero__cta",
      {
        duration: 1,
        y: 30,
        opacity: 0,
      },
      {
        duration: 1,
        y: 0,
        opacity: 1,
        ease: "expo.out",
      },
      "-=80%"
    )
    .fromTo(
      ".c-hero__recognition__card--right .c-hero__recognition__title span span",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.1,
        stagger: 0.1,
        delay: 0.1,
      },
      "-=90%"
    )
    .fromTo(
      ".c-hero__recognition__card--left .c-hero__recognition__title span span",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.1,
        stagger: 0.1,
        delay: 0.1,
      },
      "-=60%"
    );
};
