import Component from "../classes/Component";
import AutoBind from "../utils/bind";

export default class extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        menu: ".c-menu",
        menuOpen: "[data-menu-open]",
        menuClose: "[data-menu-close]",
        menuItems: "[data-menu-item]",
      },
    });

    AutoBind(this);

    this.addEventListeners();
  }

  addEventListeners() {
    const { menu, menuOpen, menuClose, menuItems } = this.elements;

    menuOpen.addEventListener("click", () => {
      menu.style.height = `${window.innerHeight}px`;
      menu.classList.add("open");
      this.element.classList.add("no-scroll");
    });

    menuClose.addEventListener("click", () => {
      menu.classList.remove("open");
      this.element.classList.remove("no-scroll");
    });

    menuItems.forEach((item) => {
      item.addEventListener("click", () => {
        menu.classList.remove("open");
        this.element.classList.remove("no-scroll");
      });
    });
  }
}
